import React, { useContext }  from "react"
import { graphql } from "gatsby"
import * as R from "ramda"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import SearchContext from "../contexts/SearchContext"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Section from "../components/page/Section"
import Hero from "../components/page/Hero"

import { makeStyles } from "@material-ui/core/styles"

// const Bold = ({ children }) => <span className="bold">{children}</span>
const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: "1em",
    marginBottom: "0.5em",
    textTransform: "uppercase",
  },
  text: {
    marginBottom: "1.25em",
  },
}))

const ServiceOfferingPage = ({ data, pageContext, location }) => {
  const classes = useStyles()

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography className={classes.heading} variant="h2" component="h2">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography className={classes.heading} variant="h3" component="h3">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography className={classes.heading} variant="h4" component="h4">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography className={classes.heading} variant="h5" component="h5">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography className={classes.heading} variant="h6" component="h6">
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.text} variant="body1" component="p">
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
      //[BLOCKS.EMBEDDED_ASSET]: (node, children) => <Typography variant="body1" component="p">{children}</Typography>,
    },
  }

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  return (
    <Layout>
      <SEO
        title={R.path(["page", "title"], data)}
        description={R.path(
          ["page", "metaDescription", "metaDescription"],
          data
        )}
        ogTitle={
          R.path(["page", "openGraphTitle"], data) ||
          R.path(["page", "heading"], data) ||
          R.path(["page", "title"], data)
        }
        ogDescription={
          R.path(
            ["page", "openGraphDescription", "openGraphDescription"],
            data
          ) ||
          R.path(["page", "leadText", "leadText"], data) ||
          R.path(["page", "metaDescription", "metaDescription"], data)
        }
        ogImg={
          R.path(["page", "openGraphImage", "file", "url"], data) ||
          R.path(["page", "headingImage", "file", "url"], data)
        }
      />
      <Hero
        backgroundImageFluid={R.path(
          ["page", "headingImage", "gatsbyImageData"],
          data || "stock"
        )}
      />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={2} implementation="css" smDown component={Hidden} />
          <Grid item xs={12} md={8}>
            <Section>
              <Typography variant="h1" component="h1">
                {R.path(["page", "heading"], data) ||
                  R.path(["page", "title"], data)}
              </Typography>
              <br />
              {R.path(["page", "leadText", "leadText"], data) && (
                <Typography variant="subtitle1" component="div">
                  {R.path(["page", "leadText", "leadText"], data)}
                </Typography>
              )}
            </Section>

            <Section>
              <Typography variant="body1" component="div">
                {R.path(["page", "content"], data) && renderRichText(R.path(["page", "content"], data), options)}
              </Typography>
            </Section>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ServiceOfferingPage

export const pageQuery = graphql`
  query serviceOfferingPageQuery($slug: String) {
    page: contentfulServiceOffering(slug: { eq: $slug }) {
      id
      title
      metaDescription {
        metaDescription
      }
      heading
      headingImage {
        file {
          url
        }
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 50,
        )
      }
      leadText {
        leadText
      }
      content {
        raw
      }
      openGraphTitle
      openGraphDescription {
        openGraphDescription
      }
      openGraphImage {
        file {
          url
        }
      }
    }
  }
`
