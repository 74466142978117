import React from "react"
import PropTypes from "prop-types"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { Container, Grid, Box } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../theme/constants"
import colors from "../theme/colors"
import typography from "../theme/typography"

const useStyles = makeStyles(theme => ({
  root: {
    background: constants.hero.background,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      background: constants.heromobile.background,
    },
  },
  content: {
    paddingTop: "50px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
    },
  },
  row: {
    width: "100%",
  },
  imageOverlay: {
    background: "rgba(0,0,0,0.35)",
  },
  headline: {
    ...typography.headline,

    margin: "auto",
    textAlign: "center",
  },
}))

const Hero = ({ headline, backgroundImageFluid, compact, children }) => {
  const classes = useStyles()

  const heroContent = (
    <Container>
      <Grid
        style={{
          minHeight: `${
            compact ? constants.heroCompact.minHeight : constants.hero.minHeight
          }`,
        }}
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.content}
      >
        {headline && (
          <Grid item className={classes.row}>
            <h1 className={classes.headline}>{headline}</h1>
          </Grid>
        )}
        {children && (
          <Grid item className={classes.row}>
            {children}
          </Grid>
        )}
      </Grid>
    </Container>
  )

  const convimage = getImage(backgroundImageFluid)
  const bgImage = convertToBgImage(convimage)

  return backgroundImageFluid ? (
    <BackgroundImage
      Tag="div"
      className={classes.root}
      backgroundColor={colors.grey[2]}
      {...bgImage}
      loading="eager"
    >
      <Box className={headline ? classes.imageOverlay : ""}>{heroContent}</Box>
    </BackgroundImage>
  ) : (
    <Box className={classes.root}>{heroContent}</Box>
  )
}

export default Hero
