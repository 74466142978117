import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 0 2em",
    marginBottom: "2em",
  },
}))

const Section = ({ dividerTop, dividerBottom, dividerColor, children }) => {
  const classes = useStyles()

  const border = `2px solid ${dividerColor}`

  return (
    <section
      className={classes.root}
      style={{
        ...(dividerTop ? { borderTop: border } : {}),
        ...(dividerBottom ? { borderBottom: border } : {}),
      }}
    >
      {children}
    </section>
  )
}

Section.propTypes = {
  dividerTop: PropTypes.bool,
  dividerBottom: PropTypes.bool,
  dividerColor: PropTypes.string,
  children: PropTypes.node,
}

Section.defaultProps = {
  dividerTop: false,
  dividerBottom: true,
  dividerColor: colors.grey["0"],
}

export default Section
